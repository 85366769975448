import React, { useRef, useEffect } from "react";

import elonMusk from "../assets/images/video.mp4";

// Images
// import notFountImage from "../assets/images/i-dont-know-confuesed.gif";
import { Grid } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    notfountImage: {
      width: "35%",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
  })
);

const NotFound = () => {
  const classes = useStyles();
  const vidRef = useRef();

  useEffect(() => {
    vidRef.current.play();
  }, []);

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Grid item container justifyContent="center" alignItems="center">
        {/* <img src={notFountImage} alt="not found page" /> */}
        <video
          src={elonMusk}
          ref={vidRef}
          muted
          autoPlay
          loop
          className={classes.notfountImage}
        ></video>
      </Grid>
    </Grid>
  );
};

export default NotFound;
