import {
  lime,
  purple,
  deepPurple,
  grey,
  indigo,
  red,
} from "@mui/material/colors";

import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    // primary: lime,
    // secondary: purple,
    info: lime,
  },
  primary: {
    main: grey[50],
  },
  indigo: {
    main: indigo[700],
  },
  deepPurple: {
    main: deepPurple[500],
    primary: deepPurple[700],
  },
  red: {
    main: red[700],
  },
  // spacing: 8
  // =========================
  buttons: {
    getInTouch: {
      main: "#24262F",
      _hover: "#4F5158",
    },
    browsProject: {
      main: "#EBEBEB",
      _hover: "#eeeeee",
    },
  },
});
