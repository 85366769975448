import React from "react";

import { Grid, Typography, ButtonGroup, Button, colors } from "@mui/material";

// Images
import heroImageSection from "../../assets/images/Hero Section Image.png";

import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    heroImageWrapper: {
      userSelect: "none",
      border: "1px solid rgba(110, 6, 242, .4)",
      borderRadius: "50%",
      width: "fit-content",
      display: "flex",
      padding: "35px",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "80% !important",
      },
    },
    childImageWrapper: {
      [theme.breakpoints.down("md")]: {
        width: "100% !important",
      },
    },
    fistText: {
      color: "rgb(51, 51, 51) !important",
      fontWeight: "400 !important",
      fontSize: "1.5rem !important",
      letterSpacing: "-2px !important",
      userSelect: "none",
    },
    secondText: {
      fontWeight: "700 !important",
      letterSpacing: "-.04em !important",
      lineHeight: "5.5rem !important",
      userSelect: "none",
    },
    thirdText: {
      fontWeight: "400 !important",
      fontSize: "1.5rem !important",
      letterSpacing: "-.5px !important",
      color: "rgb(78, 82, 90) !important",
      lineHeight: "2rem !important",
      userSelect: "none",
    },
  })
);

const HeroSection = () => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 10, md: 0 } }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.fistText} gutterBottom>
              Hey, I'm Mohammad Reza 👋🏻
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              className={classes.secondText}
              sx={{ fontSize: { xs: "3.5rem", sm: 72, md: 90 } }}
              gutterBottom
            >
              <span style={{ color: "#6E06F2" }}>Front</span>end Developer
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              className={classes.thirdText}
              gutterBottom
            >
              I'm a frontend developer based in Iran, I'll help you build
              beautiful websites your users will love.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "1.5rem" }}>
            <Button
              style={{
                marginRight: 20,
                backgroundColor: "#24262F",
                borderRadius: "8px",
              }}
              sx={{ mt: 2, width: { xs: "calc(100vw - 48px)", sm: "auto" } }}
              variant="contained"
            >
              Get in Touch
            </Button>
            <Button
              style={{
                backgroundColor: "#EBEBEB",
                color: "#000",
                borderRadius: "8px",
              }}
              sx={{ mt: 2, width: { xs: "calc(100vw - 48px)", sm: "auto" } }}
              color="primary"
              disableElevation={true}
              variant="contained"
            >
              Brows Projects
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          direction="row"
          justifyContent={{ xs: "center", md: "flex-end" }}
          alignItems="center"
        >
          <div className={classes.heroImageWrapper}>
            <img
              src={heroImageSection}
              className={classes.childImageWrapper}
              width={350}
              alt="hero image"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeroSection;
