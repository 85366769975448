import {
  Grid,
  Typography,
  ButtonGroup,
  Button,
  Link,
  Box,
} from "@mui/material";
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { Insta } from "../icons/Insta";
import { Linkedin } from "../icons/Linkedin";
import { Github } from "../icons/Github";
import { Telegram } from "../icons/Telegram";
import { createStyles, makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) =>
  createStyles({
    madeWith: {
      "&:hover": {
        color: `${theme.deepPurple.main} !important`,
      },
    },
    leftContentLinks: {
      padding: 0,
      listStyle: "none",
      display: "flex",
    },
    leftContentLinksLi: {
      paddingRight: theme.spacing(2),
      "& :focus": {
        color: "#6E06F2  !important",
      },
      "& :hover": {
        color: `#0F172A !important`,
      },
    },
    menuLink: {
      fontSize: "18px !important",
      fontWeight: "600 !important",
      color: "#4E525A !important",
    },
  })
);

const GetInTouchButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.buttons.getInTouch._hover,
  },
}));

const BrowsProjectsButton = styled(Button)(({ theme }) => ({
  color: theme.buttons.getInTouch.main,
  "&:hover": {
    color: "#8A8B8F",
    backgroundColor: theme.buttons.browsProject._hover,
  },
}));

function RightContentGrid() {
  const classes = useStyles();
  return (
    <>
      <Grid
        display={{ xs: "none", sm: "flex" }}
        justifyContent="right"
        alignItems="center"
        item
        sm={12}
      >
        <Tooltip title="Instagram">
          <a href="https://www.instagram.com/smr_es_1996/" target="_blank">
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(4),
                cursor: "pointer",
              }}
            >
              <SvgIcon>
                <Insta />
              </SvgIcon>
            </IconButton>
          </a>
        </Tooltip>
        <Tooltip title="Linkedin">
          <a
            href="https://www.linkedin.com/in/mohammad-reza-esmailzadeh-023100122/"
            target="_blank"
          >
            {" "}
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(4),
                cursor: "pointer",
              }}
            >
              <SvgIcon>
                <Linkedin />
              </SvgIcon>
            </IconButton>
          </a>
        </Tooltip>
        <Tooltip title="Github">
          <a href="https://github.com/smres" target="_blank">
            {" "}
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(4),
                cursor: "pointer",
              }}
            >
              <SvgIcon>
                <Github />
              </SvgIcon>
            </IconButton>
          </a>
        </Tooltip>
        <Tooltip title="Telegram">
          <a href="https://t.me/Mh_esmailzadeh" target="_blank">
            {" "}
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(4),
                cursor: "pointer",
              }}
            >
              <SvgIcon>
                <Telegram />
              </SvgIcon>
            </IconButton>
          </a>
        </Tooltip>
      </Grid>
      {/* <Grid item xs={12}>
        
      </Grid> */}
      <Grid item xs={12}>
        <Typography
          sx={{ textAlign: { xs: "left", sm: "right" }, mt: { xs: 6 } }}
          className={classes.footerMadeAndCopyRight}
          variant="body1"
          gutterBottom
        >
          ©2023 All Rights Reserved.
        </Typography>
        <Typography
          sx={{ textAlign: { xs: "left", sm: "right" } }}
          className={classes.footerMadeAndCopyRight}
          variant="body1"
          gutterBottom
        >
          Made with 💜 by{" "}
          <span className={classes.madeWith}>Mohammad Reza</span>
        </Typography>
      </Grid>
    </>
  );
}
function LeftContentGrid() {
  const classes = useStyles();
  return (
    <>
      <Grid
        display={{ xs: "flex", sm: "none" }}
        sx={{ mb: 3 }}
        justifyContent="right"
        alignItems="center"
        item
        sm={12}
      >
        <Tooltip title="Instagram">
          <a href="https://www.instagram.com/smr_es_1996/" target="_blank">
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(4),
                cursor: "pointer",
              }}
            >
              <SvgIcon>
                <Insta />
              </SvgIcon>
            </IconButton>
          </a>
        </Tooltip>
        <Tooltip title="Linkedin">
          <a
            href="https://www.linkedin.com/in/mohammad-reza-esmailzadeh-023100122/"
            target="_blank"
          >
            {" "}
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(4),
                cursor: "pointer",
              }}
            >
              <SvgIcon>
                <Linkedin />
              </SvgIcon>
            </IconButton>
          </a>
        </Tooltip>
        <Tooltip title="Github">
          <a href="https://github.com/smres" target="_blank">
            {" "}
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(4),
                cursor: "pointer",
              }}
            >
              <SvgIcon>
                <Github />
              </SvgIcon>
            </IconButton>
          </a>
        </Tooltip>
        <Tooltip title="Telegram">
          <a href="https://t.me/Mh_esmailzadeh" target="_blank">
            {" "}
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(4),
                cursor: "pointer",
              }}
            >
              <SvgIcon>
                <Telegram />
              </SvgIcon>
            </IconButton>
          </a>
        </Tooltip>
      </Grid>
      <Grid
        sx={{ marginBottom: 8 }}
        item
        display={{ xs: "none", sm: "block" }}
        sm={12}
      >
        <ul className={classes.leftContentLinks}>
          <li className={classes.leftContentLinksLi}>
            <Link
              underline="none"
              className={classes.menuLink}
              variant="body1"
              href="#"
            >
              Home
            </Link>
          </li>
          <li className={classes.leftContentLinksLi}>
            <Link
              underline="none"
              className={classes.menuLink}
              variant="body1"
              href="#"
            >
              Projects
            </Link>
          </li>
          <li className={classes.leftContentLinksLi}>
            <Link
              underline="none"
              className={classes.menuLink}
              variant="body1"
              href="#"
            >
              About
            </Link>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Typography
          textAlign={{ xs: "center", sm: "left" }}
          sx={{ fontSize: 22, fontWeight: 600, mb: 4 }}
          variant="body1"
          gutterBottom
        >
          Interested in working together?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {/* <ButtonGroup variant="contained" aria-label="loading button group">
          <GetInTouchButton
            sx={{
              backgroundColor: (theme) => theme.buttons.getInTouch.main,
              marginRight: (theme) => theme.spacing(4),
            }}
          >
            Get in Touch
          </GetInTouchButton>
          <BrowsProjectsButton
            sx={{ backgroundColor: (theme) => theme.buttons.browsProject.main }}
          >
            Brows Projects
          </BrowsProjectsButton>
        </ButtonGroup> */}
        <Button
          style={{
            marginRight: 20,
            backgroundColor: "#24262F",
            borderRadius: "8px",
          }}
          sx={{ mt: 2, width: { xs: "calc(100vw - 48px)", sm: "auto" } }}
          variant="contained"
        >
          Get in Touch
        </Button>
        <Button
          style={{
            backgroundColor: "#EBEBEB",
            color: "#000",
            borderRadius: "8px",
          }}
          sx={{ mt: 2, width: { xs: "calc(100vw - 48px)", sm: "auto" } }}
          color="primary"
          disableElevation={true}
          variant="contained"
        >
          Brows Projects
        </Button>
      </Grid>
    </>
  );
}

const Footer = () => {
  return (
    <Box
      sx={{
        my: (theme) => theme.spacing(8),
      }}
    >
      <Grid container>
        <Grid container item xs={12} sm={6}>
          <LeftContentGrid />
        </Grid>
        <Grid container item xs={12} sm={6}>
          <RightContentGrid />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
