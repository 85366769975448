import { Grid, Typography, LinearProgress } from "@mui/material";
import { indigo, purple } from "@mui/material/colors";
import React from "react";
import { styled } from "@mui/system";

import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    javascriptColorProgress: {
      backgroundColor: theme.indigo.main,
    },
    reactColorProgress: {
      backgroundColor: theme.red.main,
    },
  })
);

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "95%",
  height: 40,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
}));

const Skills = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography
        sx={{
          marginTop: 14,
          fontSize: { xs: "4rem", sm: 72, md: 90 },
          fontWeight: { xs: "500", md: "600" },
          marginBottom: 4,
        }}
        variant="h1"
      >
        Skills<span style={{ color: "#6E06F2" }}>.</span>
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <ul>
            <Typography variant="h3" gutterBottom>
              Frontend & Web Design
            </Typography>
            <li>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">HTML</Typography>
                <StyledLinearProgress
                  color="secondary" // رنگ قرمز
                  variant="determinate" // نوع پیشرفت مشخص
                  value={95} // مقدار پیشرفت
                />
              </div>
            </li>
            <li>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">Javascript</Typography>
                <StyledLinearProgress
                  color="warning" // رنگ قرمز
                  variant="determinate" // نوع پیشرفت مشخص
                  value={95} // مقدار پیشرفت
                />
              </div>
            </li>
            <li>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">React.js</Typography>
                <StyledLinearProgress
                  color="success"
                  variant="determinate" // نوع پیشرفت مشخص
                  value={87} // مقدار پیشرفت
                />
              </div>
            </li>
            <li>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">Responsive Design</Typography>
                <StyledLinearProgress
                  color="primary"
                  variant="determinate" // نوع پیشرفت مشخص
                  value={85} // مقدار پیشرفت
                />
              </div>
            </li>
            <li>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">Css3</Typography>
                <StyledLinearProgress
                  color="error"
                  variant="determinate" // نوع پیشرفت مشخص
                  value={90} // مقدار پیشرفت
                />
              </div>
            </li>
            <li>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">Git</Typography>
                <StyledLinearProgress
                  color="info"
                  variant="determinate" // نوع پیشرفت مشخص
                  value={75} // مقدار پیشرفت
                />
              </div>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default Skills;
