import { Container } from "@mui/material";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";

// Components
import HeroSection from "./shared/HeroSection";
import ProjectSection from "./shared/ProjectSection";
import Experience from "./shared/Experience";
import Skills from "./shared/Skills";
import Footer from "./shared/Footer";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(25),
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(16),
      },
    },
  })
);

const Landing = () => {
  const classes = useStyles();
  return (
    <Container>
      <main className={classes.container}>
        <HeroSection />
        <ProjectSection />
        <Experience />
        <Skills />
      </main>
      <footer>
        <Footer />
      </footer>
    </Container>
  );
};

export default Landing;
