import React from "react";

const AboutMe = () => {
  return (
    <div style={{ marginTop: "100px" }}>
      <h1>About</h1>
    </div>
  );
};

export default AboutMe;
