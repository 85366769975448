import React from "react";
import logoSite from "../assets/images/logo.png";
import { createStyles, makeStyles } from "@mui/styles";

import { Container, Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useScrollTrigger from "@mui/material/useScrollTrigger";
// import { theme } from "../themeConfig";

import { useNavigate, Link } from "react-router-dom";

const drawerWidth = 250;
const navItems = ["Home", "Projects", "About me", "Contact me"];

const useStyles = makeStyles((theme) =>
  createStyles({
    appbar: {
      backgroundColor: `${theme.primary.main} !important`,
      color: "#000 !important",
    },
    logoImageWrapper: {
      // height: "100px",
      width: "250px",
      padding: theme.spacing(3),
    },
    logoImage: {
      width: "100%",
    },
    desktopLogoImage: {
      margin: theme.spacing(2.5),
      width: "280px",
      [theme.breakpoints.down("sm")]: {
        width: "200px",
      },
    },
  })
);

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    // elevation: trigger ? 4 : 0,
    elevation: trigger ? 0 : 0,
  });
}

const Navbar = (props) => {
  const classes = useStyles();
  const { window } = props;
  const { handleDrawerOpen, handleDrawerClose, mobileOpen } = props;
  const navigate = useNavigate();

  function gotoForwardPage(item) {
    if (item.toLowerCase() === "about me") {
      navigate("/aboutus");
    } else if (item.toLowerCase() === "contact me") {
      navigate("/contactus");
    } else if (item.toLowerCase() === "projects") {
      navigate("/projects");
    } else if (item.toLowerCase() === "home") {
      navigate("/");
    }
  }

  const drawer = (
    <Box onClick={handleDrawerOpen} sx={{ textAlign: "center" }}>
      <div className={classes.logoImageWrapper}>
        <img src={logoSite} className={classes.logoImage} alt="" />
      </div>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem
            onClick={() => gotoForwardPage(item)}
            key={item}
            disablePadding
          >
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          sx={{
            backdropFilter: "blur(16px) saturate(180%) !important",
            backgroundColor: "rgb(255 255 255 / 75%) !important",
          }}
          className={classes.appbar}
          component="nav"
        >
          <Container>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerOpen}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Grid container alignItems="center" justifyContent="flex-end">
                <Grid item sm={4}>
                  <Box
                    sx={{
                      display: { xs: "block" },
                    }}
                  >
                    <Link to="/">
                      <img
                        className={classes.desktopLogoImage}
                        src={logoSite}
                        alt="logoSite"
                      />
                    </Link>
                  </Box>
                </Grid>
                <Grid container justifyContent={"end"} item sm={8}>
                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    {navItems.map((item) => (
                      <Button
                        onClick={() => gotoForwardPage(item)}
                        key={item}
                        sx={{ color: "#424242" }}
                      >
                        {item}
                      </Button>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default Navbar;
