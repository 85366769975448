import React from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";

// Styles
import "./Experience.css";
import { Grid } from "@mui/material";

import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    experienceTopSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    experienceBottomSection: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    experiencetikSectionContent: {
      marginRight: theme.spacing(3),
    },
    headerSection: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "3.5rem !important",
      },
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Experience = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <>
      <Typography
        sx={{
          marginTop: 14,
          fontSize: { xs: "3rem", sm: 72, md: 90 },
          fontWeight: { xs: "500", md: "600" },
          marginBottom: 4,
        }}
        variant="h1"
      >
        Experience<span style={{ color: "#6E06F2" }}>.</span>
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          // height: 300,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="Pajoohesh va Tose-e Naji" {...a11yProps(0)} />
          <Tab label="Item Two" {...a11yProps(1)} />
          <Tab label="Item Three" {...a11yProps(2)} />
          <Tab label="Item Four" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.experienceTopSection}>
                <Typography variant="h4">
                  WebApp Developer @ Pajoohesh va Tose-e Naji
                </Typography>
                <Typography variant="span">January - April 2018</Typography>
              </div>
              <div className={classes.experienceBottomSection}>
                <DoneIcon className={classes.experiencetikSectionContent} />
                <Typography>
                  Worked with a team to build a marketing website and e-commerce
                  platform for blistabloc, an ambitious startup.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.experienceTopSection}>
                <Typography variant="h4">
                  WebApp Developer @ Pajoohesh va Tose-e Naji
                </Typography>
                <Typography variant="span">January - April 2018</Typography>
              </div>
              <div className={classes.experienceBottomSection}>
                <DoneIcon className={classes.experiencetikSectionContent} />
                <Typography>
                  Worked with a team to build a marketing website and e-commerce
                  platform for blistabloc, an ambitious startup.
                </Typography>
              </div>
              <div className={classes.experienceBottomSection}>
                <DoneIcon className={classes.experiencetikSectionContent} />
                <Typography>
                  Worked with a team to build a marketing website and e-commerce
                  platform for blistabloc, an ambitious startup.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.experienceTopSection}>
                <Typography variant="h4">
                  WebApp Developer @ Pajoohesh va Tose-e Naji
                </Typography>
                <Typography variant="span">January - April 2018</Typography>
              </div>
              <div className={classes.experienceBottomSection}>
                <DoneIcon className={classes.experiencetikSectionContent} />
                <Typography>
                  Worked with a team to build a marketing website and e-commerce
                  platform for blistabloc, an ambitious startup.
                </Typography>
              </div>
              <div className={classes.experienceBottomSection}>
                <DoneIcon className={classes.experiencetikSectionContent} />
                <Typography>
                  Worked with a team to build a marketing website and e-commerce
                  platform for blistabloc, an ambitious startup.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.experienceTopSection}>
                <Typography variant="h4">
                  WebApp Developer @ Pajoohesh va Tose-e Naji
                </Typography>
                <Typography variant="span">January - April 2018</Typography>
              </div>
              <div className={classes.experienceBottomSection}>
                <DoneIcon className={classes.experiencetikSectionContent} />
                <Typography>
                  Worked with a team to build a marketing website and e-commerce
                  platform for blistabloc, an ambitious startup.
                </Typography>
              </div>
              <div className={classes.experienceBottomSection}>
                <DoneIcon className={classes.experiencetikSectionContent} />
                <Typography>
                  Worked with a team to build a marketing website and e-commerce
                  platform for blistabloc, an ambitious startup.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  );
};
export default Experience;
