import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./themeConfig";

// Styles
import styles from "./App.module.css";

// Components
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import NotFound from "./components/NotFound";
import AboutMe from "./components/AboutMe";
import Projects from "./components/Projects";

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };
  return (
    <div className={styles.container}>
      <ThemeProvider theme={theme}>
        <Navbar
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          mobileOpen={mobileOpen}
        />
        <Routes>
          <Route path="/aboutus" element={<AboutMe />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/" element={<Landing />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="/*" element={<Navigate to="/notfound" />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
