import {
  Grid,
  Typography,
  CardActionArea,
  Card,
  CardMedia,
} from "@mui/material";
import React from "react";

import ProjectImage1 from "../../src/assets/images/Shop-Project.png";

// Components
import Footer from "../../src/components/shared/Footer";

const Products = () => {
  return (
    <Grid
      container
      sx={{ marginTop: "100px" }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} md={9}>
        <Typography variant="h1">
          My <span style={{ color: "#6E06F2" }}>Best</span> Creations
        </Typography>
        <Typography variant="h5">
          Designing and Developing Robust and Stylish Web Applications for a
          Decade and Counting
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Card
          sx={{
            position: "sticky",
            top: "4.5rem",
            marginBottom: "10rem",
            borderRadius: "20px",
            background: "#fff",
            padding: (theme) => theme.spacing(0.5),
          }}
        >
          <CardActionArea>
            <CardMedia
              sx={{ borderRadius: "20px" }}
              component="img"
              height="500rem"
              image={ProjectImage1}
              alt="green iguana"
            />
          </CardActionArea>
        </Card>
        <Card
          sx={{
            position: "sticky",
            top: "4.5rem",
            marginBottom: "10rem",
            borderRadius: "20px",
            background: "#fff",
            padding: (theme) => theme.spacing(0.5),
          }}
        >
          <CardActionArea>
            <CardMedia
              sx={{ borderRadius: "20px" }}
              component="img"
              height="500rem"
              image={ProjectImage1}
              alt="green iguana"
            />
          </CardActionArea>
        </Card>
        <Card
          sx={{
            position: "sticky",
            top: "4.5rem",
            marginBottom: "10rem",
            borderRadius: "20px",
            background: "#fff",
            padding: (theme) => theme.spacing(0.5),
          }}
        >
          <CardActionArea>
            <CardMedia
              sx={{ borderRadius: "20px" }}
              component="img"
              height="500rem"
              image={ProjectImage1}
              alt="green iguana"
            />
          </CardActionArea>
        </Card>
      </Grid>

      <footer>
        <Footer />
      </footer>
    </Grid>
  );
};

export default Products;
