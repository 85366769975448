import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Grid, IconButton } from "@mui/material";

import { purple } from "@mui/material/colors";

// Images
import zarinpalProject from "../../assets/images/Zarinpal-Project.png";
import ShopProject from "../../assets/images/Shop-Project.png";

const ProjectSection = () => {
  return (
    <div>
      <Typography
        sx={{
          marginTop: 14,
          fontSize: { xs: "4rem", sm: 72, md: 90 },
          fontWeight: { xs: "500", md: "600" },
          marginBottom: 4,
        }}
        variant="h1"
      >
        Projects<span style={{ color: "#6E06F2" }}>.</span>
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={5} sx={{ padding: 2 }}>
          <Card
            style={{ border: "none", boxShadow: "none" }}
            sx={{ maxheight: 325 }}
          >
            <CardMedia
              sx={{ height: 240 }}
              image={zarinpalProject}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="span">
                Lizard
                <IconButton
                  size="small"
                  style={{
                    border: `${purple[300]} solid 1px`,
                    marginLeft: 10,
                    width: 20,
                    height: 20,
                    padding: 0,
                  }}
                >
                  <ArrowOutwardIcon style={{ width: "100%" }} />
                </IconButton>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Task management system with drag-and-drop, real-time
                collaboration, and responsive layout.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={7} sx={{ padding: 2 }}>
          <Card
            style={{ border: "none", boxShadow: "none" }}
            sx={{ maxheight: 325 }}
          >
            <CardMedia
              sx={{ height: 240 }}
              image={ShopProject}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="span">
                Lizard
                <IconButton
                  size="small"
                  style={{
                    border: `${purple[300]} solid 1px`,
                    marginLeft: 10,
                    width: 20,
                    height: 20,
                    padding: 0,
                  }}
                >
                  <ArrowOutwardIcon style={{ width: "100%" }} />
                </IconButton>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Task management system with drag-and-drop, real-time
                collaboration, and responsive layout.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={7} sx={{ padding: 2 }}>
          <Card
            style={{ border: "none", boxShadow: "none" }}
            sx={{ maxheight: 325 }}
          >
            <CardMedia
              sx={{ height: 240 }}
              image={ShopProject}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="span">
                Lizard
                <IconButton
                  size="small"
                  style={{
                    border: `${purple[300]} solid 1px`,
                    marginLeft: 10,
                    width: 20,
                    height: 20,
                    padding: 0,
                  }}
                >
                  <ArrowOutwardIcon style={{ width: "100%" }} />
                </IconButton>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Lizards are a widespread group of squamate reptiles, with over
                6,000 species, ranging across all continents except Antarctica
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} sx={{ padding: 2 }}>
          <Card
            style={{ border: "none", boxShadow: "none" }}
            sx={{ maxheight: 325 }}
          >
            <CardMedia
              sx={{ height: 240 }}
              image={zarinpalProject}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="span">
                Lizard
                <IconButton
                  size="small"
                  style={{
                    border: `${purple[300]} solid 1px`,
                    marginLeft: 10,
                    width: 20,
                    height: 20,
                    padding: 0,
                  }}
                >
                  <ArrowOutwardIcon style={{ width: "100%" }} />
                </IconButton>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Lizards are a widespread group of squamate reptiles, with over
                6,000 species, ranging across all continents except Antarctica
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectSection;
